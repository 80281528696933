import React from "react";

const DomainItem = React.memo(
  ({
    domains,
    domain,
    onClick,
    className,
    handleTransitionEnd,
    index,
    change,
    videoContent,
    showVideo,
  }) => (
    <>
      <div
        key={domain.id}
        className={`domain-item ${className}`}
        onClick={() => onClick(domain.id)}
        onTransitionEnd={
          index === domains.length - 1 ? handleTransitionEnd : null
        }
      >
        <img src={domain.image} alt={domain.name} loading="lazy" />
        <div className="domain-opaque">
          <h4>{domain.name}</h4>
        </div>
      </div>
      {/* {change && videoContent && (
        <div className={`video ${showVideo ? "visible" : ""}`}>
          {videoContent}
        </div>
      )} */}
    </>
  )
);

export default DomainItem;
