import React, { useState, useEffect, useRef } from "react";
import "./Shutter.css";
import ImageFrame from "./ImageFrame";

function ShutterTest() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const mainShutterRef = useRef(null);

  const handleChangeClass = () => {
    setIsOpen(!isOpen);
  };

  const images = [
    "https://fidusindia.in/assets/shutterImages/DarkBG.jpg",
    "https://fidusindia.in/assets/shutterImages/shutter1.jpg",
    "https://fidusindia.in/assets/shutterImages/shutter2.jpg",
    "https://fidusindia.in/assets/shutterImages/shutter3.jpg",
  ];

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset || document.documentElement.scrollTop;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const newIndex = Math.min(
      Math.floor(scrollPosition / (window.innerHeight / 4)),
      images.length - 1
    );
    setCurrentImageIndex(newIndex);
  }, [scrollPosition, images.length]);

  return (
    <div
      className="main-shutter-container"
      // ref={mainShutterRef}
      // style={{ position: isOpen ? "fixed" : "relative" }}
    >
      <div className="shutter-images">
        {isOpen &&
          images.map((image, index) => (
            <ImageFrame
              key={index}
              src={image}
              visible={index === currentImageIndex}
            />
          ))}
      </div>
      <img
        className={
          isOpen ? "rolling-shutter-image open" : "noRoll-shutter-image"
        }
        src="https://fidusindia.in/assets/shutterImages/Shutter.jpg"
        alt="Rolling Shutter Image"
      />
      <button className="shutter-btn" onClick={handleChangeClass}>
        {isOpen ? "Close" : "Open"}
      </button>
    </div>
  );
}

export default ShutterTest;
