import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import HomePage from "./Components/HomePage";
import "../src/Components/fidus.css";
import Products from "./Components/Products";
import { useLocation } from "react-router-dom";
import Blog from "./Components/Blog";
import Domains from "./Components/Domains";
import RequestDemo from "./Components/RequestDemo";
import ShutterTest from "./Shutter/ShutterTest";
import ShutterDemo from "./Shutter/ShutterDemo";
import { Triangle } from "react-loader-spinner";
import { useState, useEffect, useRef } from "react";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [navbar1, setNavbar1] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const wrapperRef = useRef(null);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar1(true);
    } else {
      setNavbar1(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <div className="App">
      {isLoading ? (
        <div className="bar-loader">
          <Triangle
            visible={true}
            // height="80"
            // width="80"
            color="red"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <div className="nav-main-container">
            <div
              className={navbar1 ? "navbar visible active" : "navbar visible"}
            >
              <a href="/" className="logo-container">
                <img
                  // src="https://fidusindia.in/assets/images/logo.png"
                  src={
                    navbar1
                      ? "https://fidusindia.in//logo-f.png"
                      : "https://fidusindia.in/assets/images/logo.png"
                  }
                  alt="logo"
                />
                <div className={navbar1 ? "elearning active" : "elearning"}>
                  <span className="e-learn-text">E-Learning</span>
                  <span>With a creative twist!</span>
                </div>
              </a>

              {location.pathname.includes("/Products") ||
              location.pathname.includes("/Blog") ||
              location.pathname.includes("/domains") ||
              location.pathname.includes("/request") ||
              location.pathname.includes("/shutter") ? (
                <></>
              ) : (
                <div className="navbar-components">
                  <a href="#aboutUs">About Us</a>
                  <a href="#domains">Domains</a>
                  <a href="#products">Products</a>
                  <a href="#blogs">Blogs</a>
                  <a href="#contact">Contact Us</a>

                  <button
                    className={navbar1 ? "demo active" : "demo"}
                    onClick={() => navigate("/request")}
                  >
                    Request Demo
                  </button>
                </div>
              )}
            </div>
          </div>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/domains" element={<Domains />} />
            <Route path="/request" element={<RequestDemo />} />
            <Route path="/shutter" element={<ShutterTest />} />
            <Route path="ShutterDemo" element={<ShutterDemo />} />
          </Routes>
        </>
      )}
    </div>
  );
}

export default App;
