import React from "react";
import "./fidus.css";
import { useState, useEffect, useRef, Suspense } from "react";
import { Link } from "react-router-dom";
import DomainItem from "./DomainItem";
const LazyVideo = React.lazy(() => import("./LazyVideo"));

function HomeDomain() {
  const [videoContent, setVideoContent] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [clickedDomainIndex, setClickedDomainIndex] = useState(null);
  const [change, setChange] = useState(false);

  const [originalDomains, setOriginalDomains] = useState([
    {
      id: 1,
      image: "https://fidusindia.in/assets2/domains/images/avition.jpg",
      name: "Aviation",
    },
    {
      id: 2,
      image: "https://fidusindia.in/assets2/domains/images/construction.jpg",
      name: "Construction",
    },
    {
      id: 3,
      image: "https://fidusindia.in/assets2/domains/images/health care.jpg",
      name: "HealthCare",
    },
    {
      id: 4,
      image: "https://fidusindia.in/assets2/domains/images/logistics.jpg",
      name: "Logistics",
    },
    {
      id: 5,
      image: "https://fidusindia.in/assets2/domains/images/retail.jpg",
      name: "Retail",
    },
  ]);

  const [domains, setDomains] = useState([
    {
      id: 1,
      image: "https://fidusindia.in/assets2/domains/images/avition.jpg",
      name: "Aviation",
    },
    {
      id: 2,
      image: "https://fidusindia.in/assets2/domains/images/construction.jpg",
      name: "Construction",
    },
    {
      id: 3,
      image: "https://fidusindia.in/assets2/domains/images/health care.jpg",
      name: "HealthCare",
    },
    {
      id: 4,
      image: "https://fidusindia.in/assets2/domains/images/logistics.jpg",
      name: "Logistics",
    },
    {
      id: 5,
      image: "https://fidusindia.in/assets2/domains/images/retail.jpg",
      name: "Retail",
    },
  ]);

  const handleDomainClick = (domainId) => {
    setChange(!change);

    const index = domains.findIndex((domain) => domain.id === domainId);

    setClickedDomainIndex(index);

    setTimeout(() => {
      const updatedDomains = [
        ...domains.slice(0, index),
        domains[index],
        ...domains.slice(index + 1),
      ];

      setDomains(updatedDomains);
    }, 400);

    if (domainId === 1) {
      setVideoContent(
        <div className="video-content">
          <Suspense fallback={<div>Loading video...</div>}>
            <LazyVideo
              src="https://fidusindia.in/assets2/domains/videos/aviation.mp4"
              autoPlay
              loop
              muted
            />
          </Suspense>
          {/* <video
            src="https://fidusindia.in/assets2/domains/videos/aviation.mp4"
            autoPlay
            loop
            muted
          /> */}
          <div className="domain-opaque-screen">
            <div id="aviation" className="domain-content">
              <h1>AVIATION</h1>
              <div className="domain-text">
                <p>
                  With years of experience in the Aviation industry, Fidus has
                  been providing comprehensive aviation training with courses
                  tailored to the specific needs of pilots, ground staff, and
                  other aviation professionals. Our unwavering commitment has
                  positioned us as a reliable partner in the aviation sector,
                  enabling individuals and organizations to achieve their goals
                  and improve performance effectively. Fidus has proudly served
                  clients from around the world, delivering training solutions
                  that transcend geographical boundaries.
                </p>
                <div className="domain-know-more">
                  <Link to={"/domains"} state={{ did: 1 }}>
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (domainId === 2) {
      setVideoContent(
        <div className="video-content">
          {/* <Suspense fallback={<div>Loading video...</div>}>
                <LazyVideo
                  src="https://fidusindia.in/assets2/domains/videos/construction.mp4"
                  autoPlay
                  loop
                  muted
                />
              </Suspense> */}
          <video
            src="https://fidusindia.in/assets2/domains/videos/construction.mp4"
            autoPlay
            loop
            muted
          />
          <div className="domain-opaque-screen">
            <div id="aviation" className="domain-content">
              <h1>CONSTRUCTION</h1>
              <div className="domain-text">
                <p>
                  In an industry renowned for its dedication to tradition and
                  meticulous attention to detail, embracing innovation may seem
                  daunting. However, in the construction sector, where safety
                  and efficiency are paramount, embracing eTraining is not just
                  a trend—it's a necessity. eTraining presents a transformative
                  opportunity to modernize how construction professionals are
                  trained, enabling them to stay updated on the latest
                  techniques and safety protocols while optimizing productivity
                  on job sites. At Fidus, we're excited to introduce our
                  eTraining solutions designed specifically for construction
                  professionals, offering a modern approach to learning that
                  enhances skill development and boosts efficiency on the job
                  site.
                </p>
                <div className="domain-know-more">
                  <Link to={"/domains"} state={{ did: 2 }}>
                    Know More{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (domainId === 3) {
      setVideoContent(
        <div className="video-content">
          {/* <Suspense fallback={<div>Loading video...</div>}>
                <LazyVideo
                  src="https://fidusindia.in/assets2/domains/videos/healthcare.mp4"
                  autoPlay
                  loop
                  muted
                />
              </Suspense> */}
          <video
            src="https://fidusindia.in/assets2/domains/videos/healthcare.mp4"
            autoPlay
            loop
            muted
          />
          <div className="domain-opaque-screen">
            <div id="aviation" className="domain-content">
              <h1>HEALTHCARE</h1>
              <div className="domain-text">
                <p>
                  In the constantly changing landscape of healthcare, it's vital
                  for professionals to remain current with the latest protocols
                  and advancements. Conventional training approaches often prove
                  cumbersome and time-intensive, resulting in gaps in knowledge
                  that can impede patient care. At Fidus, we are committed to
                  tackling these challenges head-on with our eTraining solutions
                  tailored for the healthcare sector. Our programs offer a
                  flexible and easily accessible learning experience, enabling
                  healthcare teams to stay ahead and deliver optimal patient
                  care.
                </p>
                <div className="domain-know-more">
                  <Link to={"/domains"} state={{ did: 3 }}>
                    Know More{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (domainId === 4) {
      setVideoContent(
        <div className="video-content">
          {/* <Suspense fallback={<div>Loading video...</div>}>
                <LazyVideo
                  src="https://fidusindia.in/assets2/domains/videos/logistics.mp4"
                  autoPlay
                  loop
                  muted
                />
              </Suspense> */}
          <video
            src="https://fidusindia.in/assets2/domains/videos/logistics.mp4"
            autoPlay
            loop
            muted
          />
          <div className="domain-opaque-screen">
            <div id="aviation" className="domain-content">
              <h1>LOGISTIC</h1>
              <div className="domain-text">
                <p>
                  In an industry where every moment counts and safety is of
                  utmost importance, staying ahead of the competition is
                  essential. Our custom eTraining solutions are meticulously
                  crafted to address the distinct challenges and needs of the
                  transportation and logistics sector, empowering your team to
                  perform exceptionally while adhering to industry regulations.
                  With Fidus, you can simplify your training procedures and
                  guarantee that your workforce possesses the essential skills
                  to navigate the intricacies of the logistics industry
                  efficiently.
                </p>
                <div className="domain-know-more">
                  <Link to={"/domains"} state={{ did: 4 }}>
                    Know More{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (domainId === 5) {
      setVideoContent(
        <div className="video-content">
          {/* <Suspense fallback={<div>Loading video...</div>}>
                <LazyVideo
                  src="https://fidusindia.in/assets2/domains/videos/retail.mp4"
                  autoPlay
                  loop
                  muted
                />
              </Suspense> */}
          <video
            src="https://fidusindia.in/assets2/domains/videos/retail.mp4"
            autoPlay
            loop
            muted
          />
          <div className="domain-opaque-screen">
            <div id="aviation" className="domain-content">
              <h1>RETAIL</h1>
              <div className="domain-text">
                <p>
                  Maximize the capabilities of your retail staff with our
                  advanced online training programs, meticulously crafted to
                  address the unique challenges of the retail industry. In an
                  environment where customer expectations are constantly
                  evolving, and market dynamics are ever-changing, staying ahead
                  requires continuous skill development. Partnering with Fidus
                  ensures you enhance employee performance, elevate customer
                  satisfaction, and drive business growth through effective and
                  interactive training modules.
                </p>
                <div className="domain-know-more">
                  <Link to={"/domains"} state={{ did: 5 }}>
                    Know More{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    setShowVideo(false);
  };

  const handleTransitionEnd = () => {
    setShowVideo(true);
    if (!change) {
      setDomains(originalDomains);
    }
  };
  return (
    <div>
      <div className="our-domains">
        <h2>Our Domains</h2>
      </div>
      <div className="grid-domain">
        {/* {domains.map((domain, index) => (
          <div
            key={domain.id}
            className={`domain-item ${
              change
                ? index >= 0 && index <= clickedDomainIndex
                  ? clickedDomainIndex === 1
                    ? "move-left"
                    : clickedDomainIndex === 2
                    ? "move-left1"
                    : clickedDomainIndex === 3
                    ? "move-left2"
                    : clickedDomainIndex === 4
                    ? "move-left4"
                    : ""
                  : index > clickedDomainIndex
                  ? "move-right"
                  : index === clickedDomainIndex
                  ? "active"
                  : ""
                : ""
            }`}
            onClick={() => handleDomainClick(domain.id)}
            onTransitionEnd={
              index === domains.length - 1 ? handleTransitionEnd : null
            }
          >
            <img src={domain.image} alt={domain.name} />
        
            <div className="domain-opaque">
              <h4>{domain.name}</h4>
            </div>
          </div>
        ))} */}

        {domains.map((domain, index) => (
          <DomainItem
            index={index}
            domains={domains}
            key={domain.id}
            domain={domain}
            onClick={handleDomainClick}
            className={`domain-item ${
              change
                ? index >= 0 && index <= clickedDomainIndex
                  ? clickedDomainIndex === 1
                    ? "move-left"
                    : clickedDomainIndex === 2
                    ? "move-left1"
                    : clickedDomainIndex === 3
                    ? "move-left2"
                    : clickedDomainIndex === 4
                    ? "move-left4"
                    : ""
                  : index > clickedDomainIndex
                  ? "move-right"
                  : index === clickedDomainIndex
                  ? "active"
                  : ""
                : ""
            }`}
            handleTransitionEnd={handleTransitionEnd}
            // onTransitionEnd={
            //   index === domains.length - 1 ? handleTransitionEnd : null
            // }
            // change={change}
            // videoContent={videoContent}
            // showVideo={showVideo}
          />
        ))}
        {change && videoContent && (
          <div className={`video ${showVideo ? "visible" : ""}`}>
            {videoContent}
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeDomain;
