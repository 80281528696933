import React, { useState, useEffect, Suspense } from "react";
import "./blogs.css";
import { Link, useNavigate } from "react-router-dom";
import BlogsService from "../Services/blogs.service";
const LazyImage = React.lazy(() => import("./LazyImage"));

const Blogs = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState({});
  const images = [
    "https://fidusindia.in/assets2/blogImages/blogs-1.png",
    "https://fidusindia.in/assets2/blogImages/blogs-2.png",
    "https://fidusindia.in/assets2/blogImages/blogs-3.png",
    "https://fidusindia.in/assets2/blogImages/blogs-4.png",
    "https://fidusindia.in/assets2/blogImages/blogs-5.png",
    "https://fidusindia.in/assets2/blogImages/blogs-6.png",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchBlogs = () => {
    BlogsService.fetchBlogsData()
      .then((response) => {
        if (response.data) {
          // console.log(response.data);
          setBlogs(response.data);
        } else {
          console.error("No blog found with the specified id");
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleBlogClick = (blogItem) => {
    // console.log(blogItem);
    navigate("/Blog", { state: { blogId: blogItem.id } });
  };

  return (
    <>
      <div className="blogs-container" id="blogs">
        <div className="blogs-container-heading">
          <h2>BLOGS</h2>
        </div>
        <div className="blogs-out">
          {/* <div className="blog-info">
            <p>hover on each shelf to view the blog</p>
          </div> */}
          <div className="blogs-grid">
            {Array.isArray(blogs)
              ? blogs.map((blogItem, index) => (
                  <div
                    className="blogs-item"
                    onClick={() => handleBlogClick(blogItem)}
                  >
                    <Suspense fallback={<div>Loading image...</div>}>
                      <LazyImage
                        src={images[index % images.length]}
                        alt="blogImg"
                      />
                    </Suspense>
                    <div className="blogs-title">
                      <div className="overlay">
                        <h2 title={blogItem.title}>{blogItem.title}</h2>
                        <Link to="/Blog" state={{ blogId: blogItem.id }}>
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
