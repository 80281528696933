import React, { useEffect, Suspense } from "react";
import { useLocation } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Triangle } from "react-loader-spinner";
const LazyImage = React.lazy(() => import("./LazyImage"));
const LazyVideo = React.lazy(() => import("./LazyVideo"));

function Products() {
  const location = useLocation();
  const { pid } = location.state;

  const [isLoading, setIsLoading] = useState(true);
  const [productDescrip, setProductDescrip] = useState([]);
  const [productHeading, setProductHeading] = useState("");
  const [productVideo, setProductVideo] = useState("");

  useEffect(() => {
    if (pid === 1) {
      setProductVideo("https://fidusindia.in/assets2/products/videos/lms.mp4");
      setProductHeading("LEARNING MANAGEMENT SYSTEM");
      setProductDescrip({
        mainDesc1: "Ready to enhance your organization's learning experience?",
        mainDesc2:
          " With Fidus LMS, the possibilities are limitless. Let's explore the features that enable it all.",
        videos1: "https://fidusindia.in/assets/videos/lms1.mp4",
        videos2: "https://fidusindia.in/assets/videos/lms2.mp4",
        videos3: "https://fidusindia.in/assets/videos/lms3.mp4",
        videos4: "https://fidusindia.in/assets/videos/lms4.mp4",
        title1: "Customization & Branding",
        title2: "Administrative Automation",
        title3: "Comprehensive Learning Features",
        title4: "Ongoing Enhancements",
        desc1:
          "Fidus LMS offers complete white labeling and customization options, ensuring alignment with employer branding. Every aspect, from features to color schemes to customizable themes, can be tailored to reflect the organization's identity.",
        desc2:
          "Fidus LMS streamlines learning program execution by automating administrative tasks like course marketing, learner notifications, and completion reminders. This ensures a seamless learning journey for employees.",
        desc3:
          "The platform supports blended learning with integrated virtual classrooms for instructor-led training and discussions, automatic course deactivation, and an e-store for easy access to learning resources. Responsive design, and an easy course creation process enhance user experience. Additionally, customizable certificates, reports, and analytics provide valuable insights into learner progress and course effectiveness, with support for SCORM package 1.2.",
        desc4:
          "Fidus LMS continuously evolves to meet the needs of modern workplaces. This includes integration of payment gateways for seamless transactions and continuous improvements such as enhanced reports, easy course access, and advanced analytics for personalized insights.",
      });
    }
    if (pid === 2) {
      setProductVideo(
        "https://fidusindia.in/assets2/products/videos/examiner.mp4"
      );
      setProductHeading("THE EXAMINER");
      setProductDescrip({
        img1: "https://fidusindia.in/assets/products/exm1.jpg",
        img2: "https://fidusindia.in/assets/products/exm2.jpg",
        img3: "https://fidusindia.in/assets/products/exm3.jpg",
        img4: "https://fidusindia.in/assets/products/exm4.jpg",
        img5: "https://fidusindia.in/assets/products/exm5.jpg",
        title1: "Insightful Learner and Examiner Dashboards",
        title2: "Remote Proctoring Services",
        title3: "Intuitive User Experience",
        title4: "Easy Exam Scheduler",
        title5: "Elevate Exam Creation with the Fidus Examiner Portal",
        desc1:
          "Well, isn’t that the whole point? To get insights, the examiner portal gives you the ideal data dashboard that visualizes data to provide you with in depth learner analytics that will help you evaluate the gaps in skills and competencies of your work force. This tool will take your training needs analysis to another level!",
        desc2:
          "An examination is only as effective as its execution, and we have the perfect solution! The remote proctoring feature offers capabilities such as learner authentication, comprehensive proctoring technology, and a secure exam browser that disables other tabs on the learner’s screen.",
        desc3:
          "Our team of experienced UI/UX designers have carefully crafted our online and offline examination platforms for user-friendliness. Whether accessing exams remotely or offline, our interface ensures easy navigation. With our examination module, no prior knowledge of SaaS-based products is required; it's as simple as clicking on a link.",
        desc4:
          "The exam scheduler has been designed to bring all your scheduled exams on a single dashboard, giving you easy and simple access,",
        desc5:
          "Crafting effective exams has never been easier. Choose from a variety of question formats, including multiple-choice, Drag and Drop, and descriptive questions. With convenient features like bulk uploader, creating and managing your question repository is a breeze.",
      });
    }
    if (pid === 3) {
      setProductVideo("https://fidusindia.in/assets2/products/videos/CEL.mp4");
      setProductHeading("CUSTOM COURSE DEVELOPMENT");
      setProductDescrip({
        img1: "https://fidusindia.in/assets/products/custom1.jpg",
        img2: "https://fidusindia.in/assets/products/custom2.jpg",
        img3: "https://fidusindia.in/assets/products/custom3.jpg",
        img4: "https://fidusindia.in/assets/products/custom4.jpg",
        title1: "Personalized Learning Paths",
        title2: "Interactive Content",
        title3: "Secure Platform",
        title4: "Mobile friendly",
        desc1:
          "Discover personalized learning paths curated to suit your individual needs. Easily acquire new skills efficiently through our intuitive dashboard, which helps you find the most relevant courses and resources aligned with your goals.",
        desc2:
          "Explore our interactive content feature designed to enhance your learning experience. Benefit from visual aids, audio lectures, and real-world examples that facilitate better understanding of complex topics. Assess your knowledge and track your progress through interactive quizzes.",
        desc3:
          "Rest assured, your data is in safe hands on our platform. We employ cutting-edge security measures to safeguard your information, with encrypted servers ensuring its protection against any potential threats.",
        desc4:
          "Our platform prioritizes mobile users, ensuring easy access anytime, anywhere. With our mobile apps, staying connected and learning on the go is effortless.",
      });
    }
  }, [pid]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init();
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="bar-loader-domains">
          <Triangle
            visible={true}
            // height="80"
            // width="80"
            color="red"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <div className="products-page-container">
            <div className="product-inner-page-video-container">
              <Suspense fallback={<div>Loading video...</div>}>
                <LazyVideo src={productVideo} autoPlay loop muted />
              </Suspense>
              <div className="product-opaque">
                <div className="product-heading prodct-inner-headingg">
                  <h1>{productHeading}</h1>
                </div>
              </div>
            </div>
            <div className="product-boxes">
              <div className="product-descp-boxes">
                <h4 className="pmd">
                  <span>{productDescrip.mainDesc1}</span>
                  <span>{productDescrip.mainDesc2}</span>
                </h4>
                <div
                  className={
                    pid === 1
                      ? "product-inner-container-desc pr-in-de"
                      : "product-inner-container-desc"
                  }
                >
                  <div className="product-container-desc">
                    <div
                      className="product-descrition"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <div className="product-descrip-title pr-ds-r">
                        {productDescrip.title1}
                      </div>
                      <div className="product-descrip">
                        {productDescrip.desc1}
                      </div>
                    </div>

                    {pid === 1 ? (
                      <div
                        className="product-desc-video"
                        data-aos="fade-left"
                        data-aos-duration="1500"
                      >
                        <Suspense fallback={<div>Loading video...</div>}>
                          <LazyVideo
                            src={productDescrip.videos1}
                            alt="product-video"
                            autoPlay
                            loop
                            controls
                            controlsList="nodownload"
                          />
                        </Suspense>
                      </div>
                    ) : (
                      <div
                        className="product-desc-img"
                        data-aos="fade-left"
                        data-aos-duration="1500"
                      >
                        <Suspense fallback={<div>Loading image...</div>}>
                          <LazyImage src={productDescrip.img1} alt="examiner" />
                        </Suspense>
                      </div>
                    )}
                  </div>
                  <div
                    className="product-container-desc"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                  >
                    {pid === 1 ? (
                      <div className="product-desc-video ">
                        <Suspense fallback={<div>Loading video...</div>}>
                          <LazyVideo
                            src={productDescrip.videos2}
                            alt="product-video"
                            autoPlay
                            loop
                            controls
                            controlsList="nodownload"
                          />
                        </Suspense>
                      </div>
                    ) : (
                      <div
                        className="product-desc-img"
                        data-aos="fade-right"
                        data-aos-duration="1500"
                      >
                        <Suspense fallback={<div>Loading image...</div>}>
                          <LazyImage src={productDescrip.img2} alt="examiner" />
                        </Suspense>
                      </div>
                    )}

                    <div
                      className="product-descrition"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <div className="product-descrip-title">
                        {productDescrip.title2}
                      </div>
                      <div className="product-descrip">
                        {productDescrip.desc2}
                      </div>
                    </div>
                  </div>
                  <div className="product-container-desc">
                    <div
                      className="product-descrition"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <div className="product-descrip-title pr-ds-r">
                        {productDescrip.title3}
                      </div>
                      <div className="product-descrip">
                        {productDescrip.desc3}
                      </div>
                    </div>

                    {pid === 1 ? (
                      <div
                        className="product-desc-video"
                        data-aos="fade-left"
                        data-aos-duration="1500"
                      >
                        <Suspense fallback={<div>Loading video...</div>}>
                          <LazyVideo
                            src={productDescrip.videos3}
                            alt="product-video"
                            autoPlay
                            loop
                            controls
                            controlsList="nodownload"
                          />
                        </Suspense>
                      </div>
                    ) : (
                      <div
                        className="product-desc-img"
                        data-aos="fade-left"
                        data-aos-duration="1500"
                      >
                        <Suspense fallback={<div>Loading image...</div>}>
                          <LazyImage src={productDescrip.img3} alt="examiner" />
                        </Suspense>
                      </div>
                    )}
                  </div>
                  <div className="product-container-desc">
                    {pid === 1 ? (
                      <div
                        className="product-desc-video"
                        data-aos="fade-right"
                        data-aos-duration="1500"
                      >
                        <Suspense fallback={<div>Loading video...</div>}>
                          <LazyVideo
                            src={productDescrip.videos4}
                            alt="product-video"
                            autoPlay
                            loop
                            controls
                            controlsList="nodownload"
                          />
                        </Suspense>
                      </div>
                    ) : (
                      <div
                        className="product-desc-img"
                        data-aos="fade-right"
                        data-aos-duration="1500"
                      >
                        <Suspense fallback={<div>Loading image...</div>}>
                          <LazyImage src={productDescrip.img4} alt="examiner" />
                        </Suspense>
                      </div>
                    )}

                    <div
                      className="product-descrition"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <div className="product-descrip-title">
                        {productDescrip.title4}
                      </div>
                      <div className="product-descrip">
                        {productDescrip.desc4}
                      </div>
                    </div>
                  </div>
                  {pid === 2 ? (
                    <div className="product-container-desc">
                      <div
                        className="product-descrition"
                        data-aos="fade-right"
                        data-aos-duration="1500"
                      >
                        <div className="product-descrip-title pr-ds-r">
                          {productDescrip.title5}
                        </div>
                        <div className="product-descrip">
                          {productDescrip.desc5}
                        </div>
                      </div>
                      <div
                        className="product-desc-img"
                        data-aos="fade-left"
                        data-aos-duration="1500"
                      >
                        <Suspense fallback={<div>Loading image...</div>}>
                          <LazyImage src={productDescrip.img5} alt="examiner" />
                        </Suspense>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Products;
