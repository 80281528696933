import http from "../http-common";

const submitContactUsData = (data) => {
  return http.post("/api/contusinfo", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const submitRequestDemoData = (data) => {
  return http.post("/api/compinfo", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const ContactService = {
  submitContactUsData,
  submitRequestDemoData,
};

export default ContactService;
