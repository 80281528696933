import React, { useEffect, useState, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactCardFlip from "react-card-flip";
import AOS from "aos";
import "aos/dist/aos.css";
import { Triangle } from "react-loader-spinner";
const LazyImage = React.lazy(() => import("./LazyImage"));
const LazyVideo = React.lazy(() => import("./LazyVideo"));

function Domains() {
  const location = useLocation();
  const { did } = location.state;

  const [isLoading, setIsLoading] = useState(true);
  const [domainDescrip, setDomainDescrip] = useState({});
  const [domainHeading, setDomainHeading] = useState("");
  const [domainImg, setDomainImg] = useState("");
  const [show, setShow] = useState(false);
  const [isFlipped1, setIsFlipped1] = useState(false);
  const [isFlipped2, setIsFlipped2] = useState(false);
  const [isFlipped3, setIsFlipped3] = useState(false);
  const [isFlipped4, setIsFlipped4] = useState(false);

  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (did === 1) {
      setDomainHeading("AVIATION");
      setDomainImg(
        "https://fidusindia.in/assets2/domains/images/aviationInner.png"
      );
      setDomainDescrip({
        image: "https://fidusindia.in/assets2/domains/images/aviationDummy.jpg",
        video:
          "https://fidusindia.in/assets2/domains/videos/AviationDomain.mp4",
        title: "Fidus: Your Trusted Partner in Aviation Training",
        mainDesc:
          "For the past three years, Fidus has been at the forefront of aviation training, delivering high-quality courses tailored to the unique needs of pilots, ground staff, and other aviation professionals. Our team of seasoned professionals ensures that our training programs are not only comprehensive but also relevant and up-to-date with industry standards and regulations.",
        descTitle1: "Comprehensive Training Solutions",
        descTitle2: "Customized Approach",
        descTitle3: "Global Impact",
        desc1:
          "From pilots seeking to enhance their skills to ground staff navigating complex operational challenges, Fidus offers a comprehensive range of training solutions to meet diverse needs. Our courses cover a wide range of topics, including flight operations, safety procedures, regulatory compliance, and customer service, empowering aviation professionals to excel in their roles and advance their careers.",
        desc2:
          "At Fidus, we understand that one size does not fit all when it comes to aviation training. That's why we take a customized approach to course development, tailoring our programs to the specific requirements of each client and learner. Whether you're a seasoned pilot or a newcomer to the industry, we have the expertise to design a training solution that meets your needs and exceeds your expectations.",
        desc3:
          "While our roots may be in India, our impact extends far beyond borders. Fidus has proudly served clients from around the world, delivering training solutions that transcend geographical boundaries. Our customizable and scalable programs are designed to meet the needs of international clients, ensuring that aviation professionals everywhere have access to the highest quality training.",
        domainEnd:
          "Contact Fidus India today and discover how our eLearning solutions can fuel your aviation expertise.",
      });
    }
    if (did === 2) {
      setDomainHeading("CONSTRUCTION");
      setDomainImg(
        "https://fidusindia.in/assets2/domains/images/constructionInner.png"
      );
      setDomainDescrip({
        image:
          "https://fidusindia.in/assets2/domains/images/constructionDummy.png",
        video:
          "https://fidusindia.in/assets2/domains/videos/ConstructionDomain.mp4",
        title: "Empowering Construction Industry with eTraining Solutions",
        mainDesc:
          "In the construction industry, where tradition and precision are highly valued, the idea of adopting innovative training methods like eTraining may initially seem challenging. However, eTraining is not just a passing fad – it is a powerful tool with the potential to revolutionize how construction professionals are trained and prepared for the demands of their industry.",
        descTitle1: "Challenges Addressed by eTraining in Construction",
        descTitle2: "A Natural Evolution of Training",
        descTitle3: "",
        desc1: "",
        desc2:
          "Digital training offers a dynamic and efficient way to ensure that every member of your team is equipped with the knowledge and skills they need to succeed. It empowers construction companies to navigate the complex landscape efficiently and cost-effectively, offering:",
        desc3:
          "In a constantly evolving industry, continuous learning and upskilling are imperative. eTraining is a strategic investment in the future of your construction business, empowering your workforce for success. .",
        domainEnd:
          "Embrace the potential of eTraining today and equip your workforce for success tomorrow.",
      });
    }
    if (did === 3) {
      setDomainHeading("HEALTH");
      setDomainImg(
        "https://fidusindia.in/assets2/domains/images/healthInner.png"
      );
      setDomainDescrip({
        image: "https://fidusindia.in/assets2/domains/images/healthDummy.jpg",
        video: "https://fidusindia.in/assets2/domains/videos/HealthDomain.mp4",
        title:
          "Redefining Healthcare Training: Empower Your Professionals with Fidus eTraining",
        mainDesc:
          "At Fidus, we understand the critical role healthcare professionals play in ensuring patient well-being. In today's rapidly evolving medical landscape, staying informed and up-to-date with the latest protocols and advancements is vital.",
        descTitle1: "",
        descTitle2: "This is where eTraining shines!",
        descTitle3: "Engaging Learning Experiences:",
        descTitle4: "Measurable Results for Continuous Improvement",
        desc1:
          "Traditional training methods can be restrictive and time-consuming. Busy schedules often clash with rigid classroom settings, leaving knowledge gaps and hindering optimal patient care.",
        desc2: "",
        desc3:
          "Gone are the days of monotonous lectures! We believe in making learning interactive and immersive. Our eTraining modules incorporate:",
        desc4:
          "Unlike traditional training methods, eTraining offers comprehensive performance tracking. Monitor learner progress, identify areas needing improvement, and tailor training programs to address specific knowledge gaps within your healthcare team.",
        domainEnd:
          "Ready to revolutionize healthcare training? Contact us today and let's discuss how our eTraining solutions can empower your healthcare professionals!",
      });
    }
    if (did === 4) {
      setDomainHeading("LOGISTIC");
      setDomainImg(
        "https://fidusindia.in/assets2/domains/images/logisticInner.png"
      );
      setDomainDescrip({
        image: "https://fidusindia.in/assets2/domains/images/logisticDummy.jpg",
        video: "",
        title:
          "Upskill Your Transportation and Logistics Team with Dynamic e-Training Solutions",
        mainDesc:
          "In the dynamic world of retail, where customer preferences evolve rapidly and competition is fierce, empowering your team with the right skills is essential for success. Our tailored digital upskilling solutions meet the unique demands of transportation and logistics, ensuring excellence and compliance. From product knowledge to customer service, we equip your team for success.",
        descTitle1: "Challenges:",
        descTitle2: "Why Choose Our e-Training Solutions?",
        descTitle3: "Ready to Elevate Your Team's Performance?",
        desc1:
          "1. Compliance and Safety: Logistics operations entail handling hazardous materials and navigating complex regulations. Training on safety protocols and regulatory compliance is crucial to ensure a safe working environment.",
        descc1:
          " 2. Efficient Operations: Logistics companies strive to optimize efficiency, reduce transit times, and minimize costs. Training on lean principles and inventory management helps streamline processes and improve overall efficiency.",
        descc2:
          "3. Technology Integration: The logistics industry adopts advanced technologies like GPS tracking and route optimization software. Training on these technologies enables employees to leverage them effectively, enhancing operational performance. ",
        descc3:
          "4. Driver Training and Compliance: Ensuring driver safety and regulatory compliance is vital for transportation companies. Training programs cover defensive driving, hours-of-service regulations, and vehicle inspection procedures to minimize accidents and violations. ",
        descc4:
          " 5. Data Security and Cybersecurity: With increased digitization, protecting sensitive data and preventing cyber threats is paramount. Training on cybersecurity best practices educates employees on maintaining data security and recognizing potential threats.",
        desc2: "",
        desc3:
          "Empower your team with the knowledge and skills they need to thrive in the fast-paced world of transportation and logistics. Our eTraining solutions offer unmatched flexibility, customization, and cost-effectiveness, helping your business stay ahead of the curve while prioritizing safety and compliance.",
        domainEnd:
          "Learn more about our eTraining solutions and how they can benefit your business.",
      });
    }
    if (did === 5) {
      setDomainHeading("RETAIL");
      setDomainImg(
        "https://fidusindia.in/assets2/domains/images/retailInner.png"
      );
      setDomainDescrip({
        image: "https://fidusindia.in/assets2/domains/images/retailDummy.jpg",
        video: "https://fidusindia.in/assets2/domains/videos/RetailDomain.mp4",
        title: "Elevate Your Retail Workforce with Customized Digital Training",
        mainDesc:
          "In the fast-paced world of retail, where tradition meets innovation and customer delight is key, the idea of diving into modern training methods like e-training might seem overwhelming. But, could this be the game-changer your team needs to stay ahead? After all, e-training isn't just a passing trend—it's a powerful tool that could revolutionize how your retail workforce is prepared for success. Ready to take the leap? Let’s get started.",
        descTitle1: "Challenges Addressed by eTraining in Retail Industry",
        descTitle2: "Investing in eTraining for Retail Training",
        desc1: "",
        desc2:
          "By investing in eTraining, retail organizations can empower their staff to:",
        domainEnd:
          "Contact us today to discuss your training needs and discover how our eTraining expertise can drive success for your retail business.",
      });
    }
  }, [did]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const logDescImg = [
    {
      logImg: "https://fidusindia.in/assets2/domains/images/logDesc1.png",
      logDescTitle: "Anytime, Anywhere Learning",
    },
    {
      logImg: "https://fidusindia.in/assets2/domains/images/logDesc2.png",
      logDescTitle: "Tailored to Industry Needs",
    },
    {
      logImg: "https://fidusindia.in/assets2/domains/images/logDesc4.png",
      logDescTitle: "Cost-Effective Solutions",
    },
    {
      logImg: "https://fidusindia.in/assets2/domains/images/logDesc3.png",
      logDescTitle: "Stay Current with Regulations",
    },
    {
      logImg: "https://fidusindia.in/assets2/domains/images/logDesc5.png",
      logDescTitle: "Language Barrier? Not a Problem",
    },
  ];

  const constructDescImg = [
    {
      constImg: "https://fidusindia.in/assets2/domains/images/constDesc1.png",
      constDescTitle: "Safety and Compliance",
    },
    {
      constImg: "https://fidusindia.in/assets2/domains/images/constDesc2.png",
      constDescTitle: "Skills Shortage and Training Needs",
    },
    {
      constImg: "https://fidusindia.in/assets2/domains/images/constDesc3.png",
      constDescTitle: "Project Management Efficiency",
    },
    {
      constImg: "https://fidusindia.in/assets2/domains/images/constDesc4.png",
      constDescTitle: "Technology Adoption and Innovation",
    },
    {
      constImg: "https://fidusindia.in/assets2/domains/images/constDesc5.png",
      constDescTitle: "Quality Assurance and Quality Control",
    },
  ];

  const constructCircles = [
    {
      consructDesc: "Flexibility in Learning",
    },
    {
      consructDesc: "Cost Savings",
    },
    {
      consructDesc: "Central Deployment",
    },
    {
      consructDesc: "Up-to-Date Training",
    },
    {
      consructDesc: "Onboarding and Retention",
    },
  ];

  const handleMouseEnter = (num) => {
    if (num === "1") {
      setIsFlipped1(true);
    } else if (num === "2") {
      setIsFlipped2(true);
    } else if (num === "3") {
      setIsFlipped3(true);
    } else if (num === "4") {
      setIsFlipped4(true);
    }
  };

  const handleMouseLeave = (num) => {
    if (num === "1") {
      setIsFlipped1(false);
    } else if (num === "2") {
      setIsFlipped2(false);
    } else if (num === "3") {
      setIsFlipped3(false);
    } else if (num === "4") {
      setIsFlipped4(false);
    }
  };

  useEffect(() => {
    AOS.init();
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="bar-loader-domains">
          <Triangle
            visible={true}
            // height="80"
            // width="80"
            color="red"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <div className="products-page-container">
            <div className="domain-image-container">
              <Suspense fallback={<div>Loading image...</div>}>
                <LazyImage
                  className="domain-image-inner"
                  src={
                    did === 1
                      ? "https://fidusindia.in/assets2/domains/images/aviationInner.png"
                      : did === 2
                      ? "https://fidusindia.in/assets2/domains/images/constructionInner.png"
                      : did === 3
                      ? "https://fidusindia.in/assets2/domains/images/healthInner.png"
                      : did === 4
                      ? "https://fidusindia.in/assets2/domains/images/logisticInner.png"
                      : did === 5
                      ? "https://fidusindia.in/assets2/domains/images/retailInner.png"
                      : null
                  }
                  alt="image"
                />
              </Suspense>
              <div className="product-opaque domainnn-opaqee">
                <div className="domain-headingg">
                  <h1>{domainHeading}</h1>
                </div>
              </div>
            </div>
            <div className="domain-details-inner">
              <div className="domain-main-deets">
                <div className="domain-main-deets-upper">
                  <div className="domain-title-head">
                    <div>{domainDescrip.title}</div>
                    {/* <div className="domain-title-underline"></div> */}
                  </div>
                  <div className="domain-mainDesc">
                    {domainDescrip.mainDesc}
                  </div>
                  <div className="domain-vid">
                    {/* <img src={domainDescrip.image} alt="dummy" /> */}
                    <Suspense fallback={<div>Loading image...</div>}>
                      <LazyImage src={domainDescrip.image} alt="dummy" />
                    </Suspense>
                    <div className="domain-opq-dummy">
                      {did === 4 ? null : (
                        <div className="step" onClick={handleShow}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Bold"
                            viewBox="0 0 24 24"
                            width="70"
                            height="40"
                            fill="#37466F"
                          >
                            <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
                          </svg>

                          <div className="ripple"></div>
                          <div className="ripple"></div>
                          <div className="ripple"></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="domain-description-details">
                  <div className="domain-desc-title">
                    <span className="desc-title1">
                      {domainDescrip.descTitle1}
                    </span>
                    <span>{domainDescrip.desc1}</span>
                    {did === 4 ? (
                      <div className="desc-1">
                        <span>{domainDescrip.descc1}</span>
                        <span>{domainDescrip.descc2}</span>
                        <span>{domainDescrip.descc3}</span>
                        <span>{domainDescrip.descc4}</span>
                      </div>
                    ) : did === 2 ? (
                      <div className="construction-desc2-inner">
                        {Array.isArray(constructDescImg)
                          ? constructDescImg.map((constDesc) => (
                              <div className="const-box">
                                <div className="const-img-desc">
                                  <img
                                    src={constDesc.constImg}
                                    alt="const-box"
                                  />
                                </div>
                                <span>{constDesc.constDescTitle}</span>
                                <div className="arrow-div"></div>
                                <div className="red-border-const"></div>
                              </div>
                            ))
                          : null}
                      </div>
                    ) : did === 5 ? (
                      <div className="retail-desc-cont">
                        <div
                          onMouseEnter={() => handleMouseEnter("1")}
                          onMouseLeave={() => handleMouseLeave("1")}
                        >
                          <ReactCardFlip
                            isFlipped={isFlipped1}
                            flipDirection="horizontal"
                          >
                            <div className="front-component f1-img">
                              <div className="front-opaque"></div>
                              <p>High Employee Turnover</p>
                            </div>

                            <div className="back-component">
                              <p>
                                The retail industry often faces high turnover
                                rates due to factors such as seasonal demand,
                                temporary employment, and career mobility.
                              </p>
                            </div>
                          </ReactCardFlip>
                        </div>
                        <div
                          onMouseEnter={() => handleMouseEnter("2")}
                          onMouseLeave={() => handleMouseLeave("2")}
                        >
                          <ReactCardFlip
                            isFlipped={isFlipped2}
                            flipDirection="horizontal"
                          >
                            <div className="front-component f2-img">
                              <div className="front-opaque"></div>
                              <p>Product Knowledge and Sales Skills</p>
                            </div>

                            <div className="back-component">
                              <p>
                                In afast-paced retail environment, employees
                                need to possess comprehensive product knowledge
                                and effective sales techniques to meet customer
                                needs and drive sales.
                              </p>
                            </div>
                          </ReactCardFlip>
                        </div>
                        <div
                          onMouseEnter={() => handleMouseEnter("3")}
                          onMouseLeave={() => handleMouseLeave("3")}
                        >
                          <ReactCardFlip
                            isFlipped={isFlipped3}
                            flipDirection="horizontal"
                          >
                            <div className="front-component f3-img">
                              <div className="front-opaque"></div>
                              <p>Customer Experience and Service Quality</p>
                            </div>

                            <div className="back-component">
                              <p>
                                Providing exceptional customer service is
                                paramount in the retail industry to build brand
                                loyalty and drive repeat business.
                              </p>
                            </div>
                          </ReactCardFlip>
                        </div>
                        <div
                          onMouseEnter={() => handleMouseEnter("4")}
                          onMouseLeave={() => handleMouseLeave("4")}
                        >
                          <ReactCardFlip
                            isFlipped={isFlipped4}
                            flipDirection="horizontal"
                          >
                            <div className="front-component f4-img">
                              <div className="front-opaque"></div>
                              <p>Performance Tracking and Analytics</p>
                            </div>

                            <div className="back-component">
                              <p>
                                Monitoring employee performance and assessing
                                the effectiveness of training initiatives are
                                critical for identifying areas of improvement
                                and optimizing resource allocation.
                              </p>
                            </div>
                          </ReactCardFlip>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="domain-desc-title">
                    <span className="desc-title1">
                      {domainDescrip.descTitle2}
                    </span>
                    <span>{domainDescrip.desc2}</span>
                    {did === 4 ? (
                      <div className="logistic-desc2">
                        <div className="logistic-desc2-inner">
                          {Array.isArray(logDescImg)
                            ? logDescImg.map((logDesc) => (
                                <div className="log-box">
                                  <div className="log-img-desc">
                                    <img src={logDesc.logImg} alt="log-box" />
                                  </div>
                                  <span>{logDesc.logDescTitle}</span>
                                  <div className="red-border-log"></div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    ) : did === 2 ? (
                      <div className="const-desc-circles-inner">
                        {Array.isArray(constructCircles)
                          ? constructCircles.map((construDesc, index) => (
                              <div
                                className="circle-const"
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                              >
                                <div>{construDesc.consructDesc}</div>
                              </div>
                            ))
                          : null}
                      </div>
                    ) : did === 3 ? (
                      <div className="health-desc-inner">
                        <div className="health-desc">
                          <div className="health-desc1-title">
                            Unparalleled Flexibility
                          </div>
                          <div className="health-desc1">
                            Freedom to learn anytime, anywhere
                          </div>
                        </div>
                        <div className="health-desc">
                          <div className="health-desc1-title">
                            Standardized Excellence
                          </div>
                          <div className="health-desc1">
                            Consistent knowledge and skillsets
                          </div>
                        </div>
                        <div className="health-desc">
                          <div className="health-desc1-title">
                            Effortless Scalability
                          </div>
                          <div className="health-desc1">
                            Keep your workforce equipped with the latest
                            information
                          </div>
                        </div>
                      </div>
                    ) : did === 5 ? (
                      <div className="retail-desc-cont2">
                        <div
                          className="retail-circle"
                          data-aos="fade-right"
                          data-aos-duration="1000"
                        >
                          <div className="retail-circle-img">
                            <img
                              src={
                                "https://fidusindia.in/assets2/domains/images/retailDesc1.png"
                              }
                              alt="retail1"
                            />
                          </div>
                          <div>Exceptional Customer Service</div>
                        </div>
                        <div
                          className="retail-circle"
                          data-aos="zoom-in"
                          data-aos-duration="1000"
                        >
                          <div className="retail-circle-img">
                            <img
                              src={
                                "https://fidusindia.in/assets2/domains/images/retailDesc2.png"
                              }
                              alt="retail1"
                            />
                          </div>
                          <div>Increased Retail Sales</div>
                        </div>
                        <div
                          className="retail-circle"
                          data-aos="fade-left"
                          data-aos-duration="1000"
                        >
                          <div className="retail-circle-img">
                            <img
                              src={
                                "https://fidusindia.in/assets2/domains/images/retailDesc3.png"
                              }
                              alt="retail1"
                            />
                          </div>
                          <div>Digital Transformation</div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="domain-desc-title">
                    <span className="desc-title1">
                      {domainDescrip.descTitle3}
                    </span>
                    <span>{domainDescrip.desc3}</span>
                    {did === 3 ? (
                      <div className="health-desc1-inner">
                        <div className="health-descc1 border-down">
                          Interactive Elements
                        </div>
                        <div className="health-descc1 border-up">
                          Realistic Simulations
                        </div>
                        <div className="health-descc1 border-down">
                          Gamification
                        </div>
                        <div className="health-descc1 border-up">
                          Multimedia Resources
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {did === 3 ? (
                    <div className="domain-desc-title">
                      <span className="desc-title1">
                        {domainDescrip.descTitle4}
                      </span>
                      <span>{domainDescrip.desc4}</span>
                    </div>
                  ) : null}
                </div>
                <div className="domain-end">{domainDescrip.domainEnd}</div>
                {/* <div className="contact-us-in-domain">
                  <a href="">Contact Us!</a>
                </div> */}
                <a className="read-more-product" href="/request">
                  <span>Contact Us !</span>
                </a>
              </div>
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className={did === 1 ? "modal-aviation" : "domain-modal-main"}
          >
            <Modal.Header closeButton>
              {/* <Modal.Title>Plesase select a Project.</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div className="domain-modal-body">
                <Suspense fallback={<div>Loading video...</div>}>
                  <LazyVideo
                    src={domainDescrip.video}
                    autoPlay
                    loop
                    controls
                    controlsList="nodownload"
                    muted
                  />
                </Suspense>
              </div>
            </Modal.Body>
          </Modal>
          <div className="red-contact">
            <div className="social-media">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 44 44"
                >
                  <g id="Layer_2" data-name="Layer 2" opacity="0.8">
                    <g
                      id="Layer_1"
                      data-name="Layer 1"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M23.381,44.05H20.626c-.138-.026-.276-.057-.416-.078-.918-.134-1.851-.21-2.755-.407A21.522,21.522,0,0,1,4.461,35.287,21.4,21.4,0,0,1,.215,19.073a21.317,21.317,0,0,1,6.1-12.412A21.514,21.514,0,0,1,18.217.388c.8-.141,1.607-.229,2.411-.338h2.755c.736.1,1.475.164,2.205.289A21.6,21.6,0,0,1,36.61,5.624a21.657,21.657,0,0,1,7.057,12.638c.142.8.23,1.61.338,2.416v2.75a2.582,2.582,0,0,0-.071.331,21.018,21.018,0,0,1-3,9.476A21.752,21.752,0,0,1,25.793,43.719C25,43.863,24.186,43.935,23.381,44.05Zm4.471-33.97c-1.567,0-3.087-.08-4.592.018A4.589,4.589,0,0,0,18.7,14.352a32.208,32.208,0,0,0-.107,3.43v.548H16.1v4.5h2.494v12.72H23.9V22.807h3.575c.155-1.508.305-2.98.459-4.514H23.9c0-.959-.018-1.861.007-2.762a1.044,1.044,0,0,1,1.168-1.092c.774-.014,1.546,0,2.32,0,.149,0,.3-.016.459-.024Z"
                        transform="translate(-0.005 -0.05)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <span
                onClick={() => {
                  window.open("https://twitter.com/fidus_india", "_blank");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 44 44"
                >
                  <g id="Layer_2" data-name="Layer 2" opacity="0.8">
                    <g
                      id="Layer_1"
                      data-name="Layer 1"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_12"
                        data-name="Path 12"
                        d="M20.282,0H23.72c.044.105.14.078.219.085a21.047,21.047,0,0,1,9.3,3.013A21.762,21.762,0,0,1,43.759,18.737,8.1,8.1,0,0,0,44,20.277v3.438c-.1.047-.078.142-.086.222a21.546,21.546,0,0,1-5.328,12.492,21.674,21.674,0,0,1-13.366,7.335A9.159,9.159,0,0,0,23.633,44H20.2c-.068-.136-.2-.081-.3-.093a23.707,23.707,0,0,1-3.5-.628A22.006,22.006,0,0,1,18.741.24,7.453,7.453,0,0,0,20.282,0ZM16.747,27.207a4.71,4.71,0,0,1-1.189.7,8.944,8.944,0,0,1-3.921.791c-.241,0-.521-.048-.611.26-.1.338.2.431.41.565.024.015.049.028.075.04a12.789,12.789,0,0,0,12.058.246c4.6-2.286,6.857-6.223,7.191-11.292a1.719,1.719,0,0,1,.647-1.4,7.038,7.038,0,0,0,1.429-1.593c.093-.135.248-.268.1-.458s-.314-.061-.467,0a3.131,3.131,0,0,1-1.193.32,5.009,5.009,0,0,0,1.045-1.667c.055-.156.171-.324-.016-.469-.158-.123-.3-.014-.433.062a10.973,10.973,0,0,1-1.778.764,1.142,1.142,0,0,1-1.182-.211,4.583,4.583,0,0,0-5.09-.473,4.445,4.445,0,0,0-2.534,4.338c0,.634,0,.646-.641.554a12.436,12.436,0,0,1-7.915-4.321c-.354-.413-.475-.4-.713.108a4.669,4.669,0,0,0,.49,5.017c.093.128.2.251.294.376a2.15,2.15,0,0,1-.72-.2c-.52-.229-.633-.16-.593.421a4.7,4.7,0,0,0,2.226,3.766c.115.074.265.1.327.262-.126,0-.24.009-.353,0-.709-.063-.768.015-.479.673A4.823,4.823,0,0,0,16.747,27.207Z"
                        transform="translate(-0.002)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <span
                onClick={() => {
                  window.open(
                    "https://in.linkedin.com/in/fidus-india-a5a030242?trk=public_post_follow-view-profile",
                    "_blank"
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 44 44"
                >
                  <g id="Layer_2" data-name="Layer 2" opacity="0.8">
                    <g id="Layer_1" data-name="Layer 1">
                      <path
                        id="Path_18"
                        data-name="Path 18"
                        d="M20.711,0h2.664a2.364,2.364,0,0,0,.331.07,20.987,20.987,0,0,1,9.258,2.865,21.72,21.72,0,0,1,10.7,15.273c.15.8.229,1.611.337,2.417v2.664c-.024.138-.054.275-.073.414-.115.863-.195,1.735-.36,2.59A21.977,21.977,0,0,1,25.788,43.663c-.8.135-1.6.229-2.413.337h-2.75a1.69,1.69,0,0,0-.284-.071A19.577,19.577,0,0,1,14.9,42.8Q2.913,38.367.344,25.832C.182,25.023.115,24.195,0,23.376v-2.75c.115-.8.2-1.613.337-2.414A21.968,21.968,0,0,1,17.7.433C18.7.242,19.708.142,20.711,0ZM33.561,33.566c.009-.154.024-.28.024-.4,0-2.707.032-5.414-.015-8.12a19.492,19.492,0,0,0-.336-3.365,4.33,4.33,0,0,0-3.214-3.654,8.34,8.34,0,0,0-2.074-.284,5.034,5.034,0,0,0-4.59,2.354c-.018.028-.062.038-.154.089V18.149h-4.56v15.4h4.744v-.545c0-2.391-.007-4.783,0-7.174a5.462,5.462,0,0,1,.48-2.55A2.628,2.628,0,0,1,26.8,22a2.088,2.088,0,0,1,1.8,1.865,9.723,9.723,0,0,1,.17,1.749c.018,2.491.009,4.983.01,7.474v.479ZM15.583,18.157H10.84v15.4h4.744ZM13.161,16A2.792,2.792,0,1,0,10.4,13.2,2.792,2.792,0,0,0,13.161,16Z"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <span
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/fidus_india?igsh=ZW1lYmxkeHI0YXpi",
                    "_blank"
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 44 44"
                >
                  <g
                    id="Layer_2"
                    data-name="Layer 2"
                    transform="translate(0 0.001)"
                    opacity="0.8"
                  >
                    <g
                      id="Layer_1"
                      data-name="Layer 1"
                      transform="translate(0 -0.001)"
                    >
                      <path
                        id="Path_14"
                        data-name="Path 14"
                        d="M21.986,44A22,22,0,1,1,44,22,22.012,22.012,0,0,1,21.986,44ZM34.222,22.025v-4.5a7.546,7.546,0,0,0-7.756-7.747H17.422A7.547,7.547,0,0,0,9.785,17.4q-.016,4.6,0,9.191a7.546,7.546,0,0,0,7.635,7.623q4.595.012,9.19,0a7.543,7.543,0,0,0,7.611-7.6C34.227,25.093,34.222,23.561,34.222,22.025Z"
                        transform="translate(0 0.001)"
                        fill="#fff"
                      />
                      <path
                        id="Path_15"
                        data-name="Path 15"
                        d="M115.262,105.617v4.522a4.881,4.881,0,0,1-5.091,5.1q-4.571.012-9.143,0a4.873,4.873,0,0,1-5.063-5.074q-.011-4.571,0-9.143a4.883,4.883,0,0,1,5.064-5.079q4.6-.017,9.192,0A4.881,4.881,0,0,1,115.257,101C115.269,102.541,115.262,104.082,115.262,105.617Zm-3.3-.042a6.376,6.376,0,1,0,0,.005Zm.31-5.647a1.063,1.063,0,1,0,0,.005Z"
                        transform="translate(-83.609 -83.591)"
                        fill="#fff"
                      />
                      <path
                        id="Path_16"
                        data-name="Path 16"
                        d="M147.383,143.512a3.861,3.861,0,1,1-3.871-3.852A3.861,3.861,0,0,1,147.383,143.512Z"
                        transform="translate(-121.518 -121.522)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </span>
            </div>
            <span>© 2024 Fidus, All Rights Reserved</span>
          </div>
          <div className="last"></div>
        </>
      )}
    </div>
  );
}

export default Domains;
