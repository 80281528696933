import http from "../http-common";

const fetchBlogsData = () => {
  return http.get("/api/blogs");
};

const BlogsService = {
  fetchBlogsData,
};

export default BlogsService;
