import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ContactService from "../Services/contact.service";

function RequestDemo() {
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    phone: "",
    email: "",
    companyName: "",
    jobTitle: "",
    companySize: "",
    country: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);

  const navigate = useNavigate();

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      toast("*Please fill all required fields!", {
        // position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    setValidated(true);
    event.preventDefault();
    if (validated) {
      ContactService.submitRequestDemoData(userDetails)
        .then((response) => {
          // console.log(response.data);
          if (response.data === true) {
            handleShow();
          }
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  };

  return (
    <div>
      <div className="products-page-container">
        <div className="product-video-container">
          <img
            src="https://fidusindia.in/assets2/requestDemo.jpg"
            alt="requestDemoImg"
          />
          <div className="product-opaque">
            <div className="product-heading">
              <h1 className="request-demo-heading">Request Demo</h1>
            </div>
          </div>
        </div>
        <ToastContainer />
        <div className="contact-box1 contact-box45">
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="contact-form"
            // ref={form}
          >
            <Form.Group className="contact-form-group">
              <Form.Label className="form-label">Name*</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter your name.."
                value={userDetails.name}
                onChange={handleChange}
                required
                className="input-field abc"
                validations={[required]}
              />
            </Form.Group>
            <div className="form-name">
              <Form.Group className="contact-form-group">
                <Form.Label className="form-label">Phone Number*</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  placeholder="Enter your phone number.."
                  value={userDetails.phone}
                  onChange={handleChange}
                  required
                  className="input-field abc"
                  validations={[required]}
                />
              </Form.Group>

              <Form.Group className="contact-form-group">
                <Form.Label className="form-label">Email*</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter your email.."
                  value={userDetails.email}
                  onChange={handleChange}
                  required
                  className="input-field abc"
                  validations={[required]}
                />
              </Form.Group>
            </div>
            <Form.Group className="contact-form-group">
              <Form.Label className="form-label">Company Name*</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                placeholder="Enter company name.."
                value={userDetails.companyName}
                onChange={handleChange}
                required
                className="input-field"
                validations={[required]}
              />
            </Form.Group>
            <Form.Group className="contact-form-group">
              <Form.Label className="form-label">Job Title</Form.Label>
              <Form.Control
                type="textbox"
                name="jobTitle"
                placeholder="Enter your job title.."
                value={userDetails.jobTitle}
                onChange={handleChange}
                className="input-field"
                // validations={[required]}
              />
            </Form.Group>
            <Form.Group className="contact-form-group">
              <Form.Label className="form-label">
                Company Size (no. of employees)
              </Form.Label>
              <Form.Control
                type="textbox"
                name="companySize"
                placeholder="Enter your company size.."
                value={userDetails.companySize}
                onChange={handleChange}
                className="input-field"
                // validations={[required]}
              />
            </Form.Group>
            <Form.Group className="contact-form-group">
              <Form.Label className="form-label">Country</Form.Label>
              <Form.Control
                type="textbox"
                name="country"
                placeholder="Enter country.."
                value={userDetails.country}
                onChange={handleChange}
                className="input-field"
                validations={[required]}
              />
            </Form.Group>

            <button className="submit-btn">Submit</button>
          </Form>
          <div className="contact-details">
            <div className="contact-details2 avc">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="43"
                  height="43"
                  viewBox="0 0 58 58"
                >
                  <g
                    id="Group_7213"
                    data-name="Group 7213"
                    transform="translate(0.2 0.241)"
                  >
                    <g
                      id="Layer_2"
                      data-name="Layer 2"
                      transform="translate(18.407 12.655)"
                    >
                      <g
                        id="Layer_1"
                        data-name="Layer 1"
                        transform="translate(0)"
                      >
                        <path
                          id="Path_220"
                          data-name="Path 220"
                          d="M9.39,32.118c-.623-.063-1.248-.112-1.87-.192A14.545,14.545,0,0,1,2.04,30.345,5.845,5.845,0,0,1,.588,29.173a2.125,2.125,0,0,1-.026-2.89,5.748,5.748,0,0,1,2.38-1.634,16.676,16.676,0,0,1,3.9-1.035c-.1-.13-.168-.234-.246-.335-1.4-1.84-2.83-3.654-4.18-5.527A12.59,12.59,0,0,1,.176,12.63,10.737,10.737,0,0,1,8.6.209C9.026.125,9.462.069,9.893,0h1.573c.689.125,1.389.207,2.063.385A10.686,10.686,0,0,1,21.36,9.7a11.686,11.686,0,0,1-1.929,7.409c-1.229,1.96-2.711,3.741-4.125,5.568-.23.3-.461.591-.72.92.524.1,1.013.191,1.5.3a11,11,0,0,1,3.4,1.255,6.071,6.071,0,0,1,1.288,1.036,2.177,2.177,0,0,1,0,3.087,5.653,5.653,0,0,1-2.021,1.4A16.555,16.555,0,0,1,13.9,31.923c-.622.076-1.246.128-1.87.191ZM10.7,26.632c.075-.091.129-.151.178-.215,1.258-1.647,2.523-3.285,3.764-4.942s2.534-3.3,3.691-5.032a9.975,9.975,0,0,0,1.7-7.241,9.455,9.455,0,1,0-18.1,5.059,17.9,17.9,0,0,0,2.575,4.287c1.876,2.462,3.774,4.91,5.662,7.36.176.233.343.469.531.724Zm0,4.247c.581-.033,1.567-.063,2.547-.151a16.679,16.679,0,0,0,4.495-.99,5.428,5.428,0,0,0,1.964-1.15,1.042,1.042,0,0,0-.011-1.709,5.315,5.315,0,0,0-1.1-.771,13.9,13.9,0,0,0-4.722-1.315.457.457,0,0,0-.346.184c-.737.976-1.463,1.962-2.188,2.947-.425.577-.844.575-1.267,0-.723-.987-1.455-1.968-2.175-2.957a.367.367,0,0,0-.419-.173c-.971.208-1.954.37-2.913.626a6.544,6.544,0,0,0-2.735,1.345c-.755.669-.768,1.316.025,1.939a6.976,6.976,0,0,0,1.647.951,20.256,20.256,0,0,0,7.2,1.225Z"
                          transform="translate(0)"
                          fill="#fdfdfd"
                        />
                        <path
                          id="Path_221"
                          data-name="Path 221"
                          d="M84.152,79.466A4.481,4.481,0,1,1,79.665,75,4.481,4.481,0,0,1,84.152,79.466ZM79.67,82.681a3.2,3.2,0,1,0-3.2-3.194A3.2,3.2,0,0,0,79.67,82.681Z"
                          transform="translate(-68.961 -68.775)"
                          fill="#fdfdfd"
                        />
                      </g>
                    </g>
                    <g
                      id="Ellipse_5"
                      data-name="Ellipse 5"
                      transform="translate(-0.2 -0.241)"
                      fill="none"
                      stroke="#fdfdfd"
                      stroke-width="3"
                    >
                      <circle cx="29" cy="29" r="29" stroke="none" />
                      <circle cx="29" cy="29" r="27.5" fill="none" />
                    </g>
                  </g>
                </svg>
              </span>
              <span>
                Unit No. 507, Lunkad Sky Vista, Lohegaon, Viman nagar Pune
                411014.
              </span>
            </div>
            <div className="contact-details2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="43"
                  height="43"
                  viewBox="0 0 58 58"
                >
                  <g
                    id="Group_7215"
                    data-name="Group 7215"
                    transform="translate(0.2 0.218)"
                  >
                    <g
                      id="Layer_2"
                      data-name="Layer 2"
                      transform="translate(13.806 18.407)"
                    >
                      <g
                        id="Layer_1"
                        data-name="Layer 1"
                        transform="translate(0)"
                      >
                        <path
                          id="Path_222"
                          data-name="Path 222"
                          d="M30.55,20.765a.725.725,0,0,1-.605.238Q15.271,21,.6,21c-.486,0-.6-.111-.6-.6V.6C0,.116.111,0,.6,0Q15.271,0,29.945,0a.718.718,0,0,1,.605.239ZM1.429.786c.064.072.088.1.116.131Q7.773,6.987,14,13.047a1.726,1.726,0,0,0,2.575-.03Q22.77,7,28.952.969A1.513,1.513,0,0,0,29.1.783Zm27.7,19.429-9.948-8.65c-.084.077-.158.141-.229.21-.619.6-1.233,1.214-1.859,1.81a2.507,2.507,0,0,1-3.631,0c-.455-.43-.9-.874-1.345-1.307-.28-.28-.567-.551-.856-.832L1.52,20.215Zm-9.4-9.192,10.035,8.728V1.25ZM.784,19.851,10.71,10.91.784,1.242Z"
                          transform="translate(0)"
                          fill="#fdfdfd"
                        />
                      </g>
                    </g>
                    <g
                      id="Ellipse_7"
                      data-name="Ellipse 7"
                      transform="translate(-0.2 -0.218)"
                      fill="none"
                      stroke="#fdfdfd"
                      stroke-width="2"
                    >
                      <circle cx="29" cy="29" r="29" stroke="none" />
                      <circle cx="29" cy="29" r="28" fill="none" />
                    </g>
                  </g>
                </svg>
              </span>
              <span>bdm@fidusindia.in</span>
            </div>
            <div className="contact-details2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="43"
                  height="43"
                  viewBox="0 0 58 58"
                >
                  <g
                    id="Group_7214"
                    data-name="Group 7214"
                    transform="translate(0.2 0.181)"
                  >
                    <g
                      id="Layer_2"
                      data-name="Layer 2"
                      transform="translate(16.107 16.107)"
                    >
                      <g
                        id="Layer_1"
                        data-name="Layer 1"
                        transform="translate(0 0)"
                      >
                        <path
                          id="Path_155"
                          data-name="Path 155"
                          d="M0,5.6A5.562,5.562,0,0,1,1.856,1.543,5.927,5.927,0,0,1,3.752.185,2.236,2.236,0,0,1,6.44.774Q8.121,2.638,9.788,4.512a2.533,2.533,0,0,1-.012,3.263,2.8,2.8,0,0,1-.451.474,1.948,1.948,0,0,0-.691,2.207,3.814,3.814,0,0,0,.49,1.073A15.494,15.494,0,0,0,13.388,16a6.479,6.479,0,0,0,1.488.721,1.91,1.91,0,0,0,2.223-.6,2.709,2.709,0,0,1,3.418-.582,2.257,2.257,0,0,1,.414.3q1.814,1.677,3.62,3.363a2.043,2.043,0,0,1,.519,2.689,6.305,6.305,0,0,1-4.644,3.4,6.967,6.967,0,0,1-3.331-.468,21.921,21.921,0,0,1-5.415-2.95,33.887,33.887,0,0,1-9.263-9.754A15.86,15.86,0,0,1,.281,7.352C.146,6.776.091,6.183,0,5.6Zm19.718,18.85c.323-.038.65-.054.968-.12A5.727,5.727,0,0,0,23.563,22.5a3.994,3.994,0,0,0,.647-.893,1.324,1.324,0,0,0-.307-1.764c-1.189-1.11-2.38-2.223-3.579-3.319a1.689,1.689,0,0,0-.637-.363,1.85,1.85,0,0,0-1.979.65,2.809,2.809,0,0,1-1.66.887,3.659,3.659,0,0,1-2.481-.514,15.522,15.522,0,0,1-4.684-4.371,6.235,6.235,0,0,1-1.065-1.831,2.957,2.957,0,0,1,.935-3.445A1.692,1.692,0,0,0,9.06,5.071Q7.4,3.206,5.728,1.355A1.293,1.293,0,0,0,4.3.952a2.162,2.162,0,0,0-.768.372A17.677,17.677,0,0,0,1.951,2.839a4.2,4.2,0,0,0-.922,3.577,11.409,11.409,0,0,0,1.487,4.041,32.02,32.02,0,0,0,7.723,9.156,27.2,27.2,0,0,0,6.178,4A8.843,8.843,0,0,0,19.717,24.447Z"
                          transform="translate(0 0.029)"
                          fill="#fdfdfd"
                        />
                      </g>
                    </g>
                    <g
                      id="Ellipse_6"
                      data-name="Ellipse 6"
                      transform="translate(-0.2 -0.181)"
                      fill="none"
                      stroke="#fdfdfd"
                      stroke-width="2"
                    >
                      <circle cx="29" cy="29" r="29" stroke="none" />
                      <circle cx="29" cy="29" r="28" fill="none" />
                    </g>
                  </g>
                </svg>
              </span>
              <span>+91 7042341006</span>
            </div>
          </div>
          <div className="red-contact">
            <div className="social-media">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 44 44"
                >
                  <g id="Layer_2" data-name="Layer 2" opacity="0.8">
                    <g
                      id="Layer_1"
                      data-name="Layer 1"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M23.381,44.05H20.626c-.138-.026-.276-.057-.416-.078-.918-.134-1.851-.21-2.755-.407A21.522,21.522,0,0,1,4.461,35.287,21.4,21.4,0,0,1,.215,19.073a21.317,21.317,0,0,1,6.1-12.412A21.514,21.514,0,0,1,18.217.388c.8-.141,1.607-.229,2.411-.338h2.755c.736.1,1.475.164,2.205.289A21.6,21.6,0,0,1,36.61,5.624a21.657,21.657,0,0,1,7.057,12.638c.142.8.23,1.61.338,2.416v2.75a2.582,2.582,0,0,0-.071.331,21.018,21.018,0,0,1-3,9.476A21.752,21.752,0,0,1,25.793,43.719C25,43.863,24.186,43.935,23.381,44.05Zm4.471-33.97c-1.567,0-3.087-.08-4.592.018A4.589,4.589,0,0,0,18.7,14.352a32.208,32.208,0,0,0-.107,3.43v.548H16.1v4.5h2.494v12.72H23.9V22.807h3.575c.155-1.508.305-2.98.459-4.514H23.9c0-.959-.018-1.861.007-2.762a1.044,1.044,0,0,1,1.168-1.092c.774-.014,1.546,0,2.32,0,.149,0,.3-.016.459-.024Z"
                        transform="translate(-0.005 -0.05)"
                        // fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <span
                onClick={() => {
                  window.open("https://twitter.com/fidus_india", "_blank");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 44 44"
                >
                  <g id="Layer_2" data-name="Layer 2" opacity="0.8">
                    <g
                      id="Layer_1"
                      data-name="Layer 1"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_12"
                        data-name="Path 12"
                        d="M20.282,0H23.72c.044.105.14.078.219.085a21.047,21.047,0,0,1,9.3,3.013A21.762,21.762,0,0,1,43.759,18.737,8.1,8.1,0,0,0,44,20.277v3.438c-.1.047-.078.142-.086.222a21.546,21.546,0,0,1-5.328,12.492,21.674,21.674,0,0,1-13.366,7.335A9.159,9.159,0,0,0,23.633,44H20.2c-.068-.136-.2-.081-.3-.093a23.707,23.707,0,0,1-3.5-.628A22.006,22.006,0,0,1,18.741.24,7.453,7.453,0,0,0,20.282,0ZM16.747,27.207a4.71,4.71,0,0,1-1.189.7,8.944,8.944,0,0,1-3.921.791c-.241,0-.521-.048-.611.26-.1.338.2.431.41.565.024.015.049.028.075.04a12.789,12.789,0,0,0,12.058.246c4.6-2.286,6.857-6.223,7.191-11.292a1.719,1.719,0,0,1,.647-1.4,7.038,7.038,0,0,0,1.429-1.593c.093-.135.248-.268.1-.458s-.314-.061-.467,0a3.131,3.131,0,0,1-1.193.32,5.009,5.009,0,0,0,1.045-1.667c.055-.156.171-.324-.016-.469-.158-.123-.3-.014-.433.062a10.973,10.973,0,0,1-1.778.764,1.142,1.142,0,0,1-1.182-.211,4.583,4.583,0,0,0-5.09-.473,4.445,4.445,0,0,0-2.534,4.338c0,.634,0,.646-.641.554a12.436,12.436,0,0,1-7.915-4.321c-.354-.413-.475-.4-.713.108a4.669,4.669,0,0,0,.49,5.017c.093.128.2.251.294.376a2.15,2.15,0,0,1-.72-.2c-.52-.229-.633-.16-.593.421a4.7,4.7,0,0,0,2.226,3.766c.115.074.265.1.327.262-.126,0-.24.009-.353,0-.709-.063-.768.015-.479.673A4.823,4.823,0,0,0,16.747,27.207Z"
                        transform="translate(-0.002)"
                        // fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <span
                onClick={() => {
                  window.open(
                    "https://in.linkedin.com/in/fidus-india-a5a030242?trk=public_post_follow-view-profile",
                    "_blank"
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 44 44"
                >
                  <g id="Layer_2" data-name="Layer 2" opacity="0.8">
                    <g id="Layer_1" data-name="Layer 1">
                      <path
                        id="Path_18"
                        data-name="Path 18"
                        d="M20.711,0h2.664a2.364,2.364,0,0,0,.331.07,20.987,20.987,0,0,1,9.258,2.865,21.72,21.72,0,0,1,10.7,15.273c.15.8.229,1.611.337,2.417v2.664c-.024.138-.054.275-.073.414-.115.863-.195,1.735-.36,2.59A21.977,21.977,0,0,1,25.788,43.663c-.8.135-1.6.229-2.413.337h-2.75a1.69,1.69,0,0,0-.284-.071A19.577,19.577,0,0,1,14.9,42.8Q2.913,38.367.344,25.832C.182,25.023.115,24.195,0,23.376v-2.75c.115-.8.2-1.613.337-2.414A21.968,21.968,0,0,1,17.7.433C18.7.242,19.708.142,20.711,0ZM33.561,33.566c.009-.154.024-.28.024-.4,0-2.707.032-5.414-.015-8.12a19.492,19.492,0,0,0-.336-3.365,4.33,4.33,0,0,0-3.214-3.654,8.34,8.34,0,0,0-2.074-.284,5.034,5.034,0,0,0-4.59,2.354c-.018.028-.062.038-.154.089V18.149h-4.56v15.4h4.744v-.545c0-2.391-.007-4.783,0-7.174a5.462,5.462,0,0,1,.48-2.55A2.628,2.628,0,0,1,26.8,22a2.088,2.088,0,0,1,1.8,1.865,9.723,9.723,0,0,1,.17,1.749c.018,2.491.009,4.983.01,7.474v.479ZM15.583,18.157H10.84v15.4h4.744ZM13.161,16A2.792,2.792,0,1,0,10.4,13.2,2.792,2.792,0,0,0,13.161,16Z"
                        // fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <span
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/fidus_india?igsh=ZW1lYmxkeHI0YXpi",
                    "_blank"
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 44 44"
                >
                  <g
                    id="Layer_2"
                    data-name="Layer 2"
                    transform="translate(0 0.001)"
                    opacity="0.8"
                  >
                    <g
                      id="Layer_1"
                      data-name="Layer 1"
                      transform="translate(0 -0.001)"
                    >
                      <path
                        id="Path_14"
                        data-name="Path 14"
                        d="M21.986,44A22,22,0,1,1,44,22,22.012,22.012,0,0,1,21.986,44ZM34.222,22.025v-4.5a7.546,7.546,0,0,0-7.756-7.747H17.422A7.547,7.547,0,0,0,9.785,17.4q-.016,4.6,0,9.191a7.546,7.546,0,0,0,7.635,7.623q4.595.012,9.19,0a7.543,7.543,0,0,0,7.611-7.6C34.227,25.093,34.222,23.561,34.222,22.025Z"
                        transform="translate(0 0.001)"
                        // fill="#fff"
                      />
                      <path
                        id="Path_15"
                        data-name="Path 15"
                        d="M115.262,105.617v4.522a4.881,4.881,0,0,1-5.091,5.1q-4.571.012-9.143,0a4.873,4.873,0,0,1-5.063-5.074q-.011-4.571,0-9.143a4.883,4.883,0,0,1,5.064-5.079q4.6-.017,9.192,0A4.881,4.881,0,0,1,115.257,101C115.269,102.541,115.262,104.082,115.262,105.617Zm-3.3-.042a6.376,6.376,0,1,0,0,.005Zm.31-5.647a1.063,1.063,0,1,0,0,.005Z"
                        transform="translate(-83.609 -83.591)"
                        // fill="#fff"
                      />
                      <path
                        id="Path_16"
                        data-name="Path 16"
                        d="M147.383,143.512a3.861,3.861,0,1,1-3.871-3.852A3.861,3.861,0,0,1,147.383,143.512Z"
                        transform="translate(-121.518 -121.522)"
                        // fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </span>
            </div>
            <span>© 2022 Fidus, All Rights Reserved</span>
          </div>
          <div className="last"></div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          {/* <Modal.Title>Plesase select a Project.</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="thanks-modal">
          <svg
            className="modal-svg-left"
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 24 24"
            fill="gray"
          >
            <path d="m21,21.5v2.5h-2v-2.5c0-1.325-1.039-2.402-2.344-2.484l-2.656,3.984-2-3-2,3-2.656-3.984c-1.305.082-2.344,1.159-2.344,2.484v2.5h-2v-2.5c0-2.481,2.019-4.5,4.5-4.5h9c2.481,0,4.5,2.019,4.5,4.5ZM12.824,6.095c1.255.289,2.278,1.304,2.575,2.557.201.847.091,1.659-.238,2.348h1.306c.501,0,.939-.368.997-.866.068-.587.043-1.202-.091-1.831-.444-2.079-2.131-3.756-4.213-4.184-3.537-.727-6.659,1.97-6.659,5.381,0,1.469.579,2.805,1.52,3.792.04-.848.346-1.627.836-2.256-.228-.464-.356-.986-.356-1.537,0-2.199,2.038-3.931,4.324-3.405Zm-5.953-2.067c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z" />
          </svg>
          <svg
            className="modal-right-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 154 154"
          >
            <g
              id="Group_97"
              data-name="Group 97"
              transform="translate(-7288 -3724)"
              opacity="0.13"
            >
              <path
                id="Path_283"
                data-name="Path 283"
                d="M633,240.39h27.4L646.19,283a1.88,1.88,0,0,0,3.29,1.72l45.07-60.1a1.88,1.88,0,0,0-1.5-3H665.6L679.81,179a1.89,1.89,0,0,0-3.29-1.73l-45.07,60.11a1.88,1.88,0,0,0,1.55,3.01Zm39.63-51.59-11.36,34.09a1.88,1.88,0,0,0,1.78,2.48h26.29L653.42,273.2l11.36-34.09a1.87,1.87,0,0,0-1.78-2.47H636.71Z"
                transform="translate(6702 3570)"
                fill="#ea2928"
              />
              <path
                id="Path_284"
                data-name="Path 284"
                d="M588.47,307.9l34.21-11.36A75.94,75.94,0,0,0,663,308a77,77,0,1,0-77-77,75.94,75.94,0,0,0,11.46,40.32L586.1,305.53a1.87,1.87,0,0,0,2.37,2.37Zm12.83-36.24a1.87,1.87,0,0,0-.2-1.61A72.19,72.19,0,0,1,589.76,231,73.24,73.24,0,1,1,663,304.24a72.17,72.17,0,0,1-39-11.34,1.85,1.85,0,0,0-1.61-.2l-31.5,10.46Z"
                transform="translate(6702 3570)"
                fill="black"
              />
            </g>
          </svg>
          <p>Thank You for contacting us!</p>
          <span>We will get in touch with you soon.</span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              navigate("/");
              handleClose();
            }}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RequestDemo;
