import React from "react";
import "./Shutter.css";

const ImageFrame = ({ src, visible }) => {
  return (
    <div className={visible ? "image-frame visible" : "image-frame"}>
      <img
        src={src}
        alt="Frame"
        style={{ width: "100%", height: "auto", display: "block" }}
      />
    </div>
  );
};

export default ImageFrame;
