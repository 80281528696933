import React, { useState, useEffect } from "react";
import ImageFrame from "./ImageFrame";

const ShutterDemo = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "https://fidusindia.in/assets/shutterImages/DarkBG.jpg",
    "https://fidusindia.in/assets/shutterImages/shutter1.jpg",
    "https://fidusindia.in/assets/shutterImages/shutter2.jpg",
    "https://fidusindia.in/assets/shutterImages/shutter3.jpg",
  ];

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset || document.documentElement.scrollTop;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const newIndex = Math.min(
      Math.floor(scrollPosition / (window.innerHeight / 4)),
      images.length - 1
    );
    setCurrentImageIndex(newIndex);
  }, [scrollPosition, images.length]);

  return (
    <>
      <div>
        {images.map((image, index) => (
          <ImageFrame
            key={index}
            src={image}
            visible={index === currentImageIndex}
          />
        ))}
      </div>
      <div>
        <img
          src="https://fidusindia.in/assets/shutterImages/DarkBG.jpg"
          alt=""
          style={{ width: "100%", height: "auto", display: "block" }}
        />
      </div>
    </>
  );
};

export default ShutterDemo;
